import React, { useState } from 'react'

import Hero from '../components/Hero'
import Accordion from '../components/Accordion'
import LanguageTabs, { getLanguage } from '../components/LanguageTabs'
import faqs_EN from '../content/faqs/faqs_EN'
import faqs_ES from '../content/faqs/faqs_ES'
import CallToAction from '../components/CallToAction'

const content = {
  EN: {
    heading: 'Frequently asked questions',
    faqs: faqs_EN,
  },
  ES: {
    heading: 'Preguntas frecuentes',
    faqs: faqs_ES,
  },
}

const FAQsPage = () => {
  const language = getLanguage()
  const faqs = content[language].faqs

  const initialActiveIndices = Array(faqs.length).fill([])
  const [activeIndices, setActiveIndices] = useState(initialActiveIndices)

  const collapseAll = (categoryIndex) => {
    let newActiveIndices = [...activeIndices]
    newActiveIndices[categoryIndex] = []
    setActiveIndices(newActiveIndices)
  }
  const expandAll = (categoryIndex) => {
    let newActiveIndices = [...activeIndices]
    const expandedIndices = faqs[categoryIndex].questions.map((_, i) => i)
    newActiveIndices[categoryIndex] = expandedIndices
    setActiveIndices(newActiveIndices)
  }

  return (
    <main className="faqsPage">
      <Hero heading={content[language].heading} image="clock-devices" />
      <LanguageTabs />
      <div className="faq-section">
        <h2>{content[language].heading}</h2>
        {faqs.map((faq, i) => {
          return (
            <div className="faq-category" key={i}>
              <div className="faq-category-header">
                <h3>{faq.category}</h3>
                <div className="faq-collapse-actions">
                  <button className="action" onClick={() => collapseAll(i)}>
                    Collapse All
                  </button>
                  <div>|</div>
                  <button className="action" onClick={() => expandAll(i)}>
                    Expand All
                  </button>
                </div>
              </div>
              <Accordion className="faq-category-content" panels={faq.questions} activeIndices={activeIndices[i]} />
            </div>
          )
        })}
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Have more questions about your Aidia System?"
            linkText="Contact an Aidia Specialist"
            linkUrl="/patient-support"
            color="green"
          />
        </div>
      </div>
    </main>
  )
}

export default FAQsPage
