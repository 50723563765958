import React from 'react'
import { getSearchParams } from 'gatsby-query-params'
import { navigate } from 'gatsby'

/**
 * Returns the language (locale) based on the query param
 */
export const getLanguage = () => {
  const queryParamLang = getSearchParams().lang
  return ['EN', 'ES'].includes(queryParamLang) ? queryParamLang : 'EN'
}

/**
 *
 * @param {*} content An object that maps locales to the associated language entry maps
 * @returns A function that takes a language entry key and returns the value corresponding to the active language (locale)
 */
export const useLang = (content) => {
  const language = getLanguage()

  return (section) => {
    return content[language][section]
  }
}

export default function LanguageTabs() {
  const language = getLanguage()
  const queryParams = getSearchParams()

  const switchLanguage = (lang) => {
    queryParams.lang = lang
    const paramsStr = new URLSearchParams(queryParams).toString()
    navigate(`?${paramsStr}`)
  }

  return (
    <div className="language-tabs-wrapper">
      <div className="language-tabs">
        <button className={`language-tab ${language === 'EN' ? 'active' : ''}`} onClick={() => switchLanguage('EN')}>
          English
        </button>
        <button className={`language-tab ${language === 'ES' ? 'active' : ''}`} onClick={() => switchLanguage('ES')}>
          En Español
        </button>
      </div>
    </div>
  )
}
