import rechargeBottle from '../../images/recharge-bottle.png'

export default [
  {
    category: `Sobre el sistema Aidia™`,
    questions: [
      {
        title: `¿Por qué es importante la adhesión?`,
        content: `<p>La adhesión a la medicación simplemente significa tomar su medicamento según lo prescrito por su médico. Esto incluye tomar la dosis correcta en el momento adecuado y reabastecer las recetas de manera oportuna.</p>
        <p>El problema es que puede ser difícil de mantener. Hasta el 50% de las personas omiten dosis con regularidad por una razón u otra. La mala adhesión puede tener un impacto negativo en su salud. Puede prolongar su afección o complicarla. La clave para la adhesión es la <strong>coherencia</strong> y, a menudo, requiere un buen sistema de apoyo para ayudarlo a mantenerse encaminado.</p>
        <p>Puede obtener más información sobre la adhesión <a class="faq-link" href="https://www.fda.gov/drugs/special-features/why-you-need-take-your-medications-prescribed-or-instructed" target="_blank" rel="noopener noreferrer">aquí</a>.</p>`,
      },
      {
        title: `¿Qué es exactamente el sistema Aidia?`,
        content: `<p>Aidia es un sistema de adhesión inteligente diseñado para ayudar a personas como usted a mantenerse al día con los horarios de dosificación de medicamentos. Este sistema utiliza frascos y dispositivos inteligentes para avisarle de la hora de su dosis programada, se fija si ha tomado su dosis y le recuerda si llega tarde o se ha olvidado una dosis.</p>
        <p>Aidia también brinda un apoyo amigable que puede ayudar a cambiar la configuración e incluso lo conecta con su equipo de farmacia o con el soporte de atención para obtener asistencia para el reabastecimiento o atención adicional.</p>`,
      },
      {
        title: `¿Cuánto me costará utilizar Aidia?`,
        content: `<p>Nada. Es probable que su sistema Aidia esté patrocinado por el fabricante de su medicamento u organización de atención médica, lo que nos permite brindarle este servicio sin costo para usted.</p>`,
      },
      {
        title: `¿Cómo utilizo Aidia?`,
        content: `<p>Una hora antes de que se acerque la hora de la dosis, el frasco inteligente de Aidia emitirá un brillo azul como un suave recordatorio. Tome su medicamento en cualquier momento durante este tiempo y sus recordatorios se detendrán. A la hora programada para la dosis, Aidia sonará brevemente y se enviará un mensaje de texto o una llamada telefónica como recordatorio. Las alertas terminarán una vez que abra el frasco y tome su medicamento.</p>
        <p>¿Quiere personalizar o cambiar alguna de estas funciones? Llame a su farmacia o especialista de soporte de Aidia para ajustar la configuración de las notificaciones de recordatorio.</p>`,
      },
      {
        title: `¿Qué sucede si no tomo mis medicamentos a tiempo?`,
        content: `<p>Si usted o su ser querido omiten una dosis, se activan las alertas de recordatorio. Las alertas de recordatorio (campanillas, mensajes de texto y/o llamadas telefónicas) están diseñadas para garantizar que se tome una dosis lo antes posible.</p>`,
      },
      {
        title: `¿Cómo puedo cambiar los horarios o la configuración de mi dosis?`,
        content: `<p>Simplemente llame a su farmacia o comuníquese con un especialista de soporte de Aidia. Podemos actualizar sus tiempos de dosis, pausar recordatorios y personalizar la configuración de su frasco o teléfono. Simplemente llame al número impreso en la parte inferior de su frasco para mayor comodidad.</p>`,
      },
    ],
  },
  {
    category: `Acerca de las alertas recordatorias de Aidia`,
    questions: [
      {
        title: `¿Qué son las alertas recordatorias?`,
        content: `<p>Las alertas recordatorias son solo eso, recordatorios, enviados por Aidia para ayudarlo a recordar la dosificación de sus medicamentos. A la hora programada para su dosis, su frasco inteligente de Aidia sonará brevemente. También recibirá un mensaje de texto o una llamada telefónica. Las alertas terminarán una vez que abra el frasco y tome su medicamento.</p>`,
      },
      {
        title: `¿Por qué no se activó la alerta de recordatorio?`,
        content: `<p>Las alertas recordatorias están conectadas a cuándo y cómo interactúa con su frasco inteligente Aidia. Por lo tanto, digamos que abre el frasco cerca de la hora programada para la dosis, sus recordatorios se desactivarán hasta la siguiente dosis.</p>`,
      },
      {
        title: `¿Cómo puede mi cuidador también recibir recordatorios por mensaje de texto o por teléfono?`,
        content: `<p>Un especialista en soporte de Aidia puede ayudarlo con eso. Llámenos para agregar un número de teléfono para el cuidador en cuestión de minutos.</p>`,
      },
    ],
  },
  {
    category: `Acerca de su frasco inteligente Aidia`,
    questions: [
      {
        title: `He recibido mi frasco inteligente Aidia. ¿Qué debo hacer ahora?`,
        content: `<p>Una vez que haya sacado la frasco inteligente de Aidia del paquete, simplemente retire la pestaña naranja para activar, si su farmacia aún no lo ha hecho, y estará listo para comenzar. Su frasco no requiere configuración y está completamente cargada por hasta 10 meses en la mayoría de los casos.</p>
        <p>Para ver lo fácil que es comenzar a usar Aidia, <a href="https://youtu.be/v-1nzNUIN6M" target="_blank">mire este video</a>.</p>
        <p>Consulte el Manual de Usuario que recibió con su frasco inteligente Aidia para obtener más información.</p>`,
      },
      {
        title: `Mi frasco llegó vacía. ¿Dónde está mi medicación?`,
        content: `<p>En ciertos casos, es posible que su frasco inteligente Aidia no venga con su medicamento adentro. Si ese es el caso y su medicamento viene por separado, simplemente transfiera el contenido de su frasco de prescripción al frasco inteligente de Aidia.</p>
        <p>Conserve y refiera a la etiqueta de prescripción de la farmacia para conocer las instrucciones de uso de su medicamento y cualquier otro detalle del medicamento que necesite. Es posible que deba consultar, por ejemplo, la Información de prescripción para el paciente del medicamento.</p>`,
      },
      {
        title: `¿Qué pasa si todas mis píldoras no caben en el frasco inteligente de Aidia?`,
        content: `<p>Eso puede suceder ocasionalmente ya que los tamaños de las píldoras varían. Pero no es un problema. Simplemente deje las píldoras que no caben en el frasco inteligente de Aidia en el envase de prescripción original. Transfiera el resto a su frasco inteligente cuando se esté agotando.</p>
        <p>El mejor momento para transferir sus píldoras es en un horario de dosis programado cuando ya haya abierto su frasco de Aidia.</p>`,
      },
      {
        title: `¿Qué sucede cuando obtengo un reabastecimiento de una receta?`,
        content: `<p>Cuando reciba su reposición, transfiera el medicamento a su frasco Aidia. Espere hasta su próxima dosis programada para hacer la transferencia, ya que abrir el frasco se contará como tomar una dosis, y eso puede alterar su horario.</p>
        <p>Conserve y consulte la etiqueta de la receta de la farmacia para conocer las instrucciones de uso de su medicamento (y cualquier otro detalle del medicamento al que deba consultar, como la Información de prescripción del medicamento para el paciente).</p>`,
      },
      {
        title: `¿Por qué mi frasco está brillando antes de la hora de mi dosis?`,
        content: `<p>Ese resplandor azul aparece una hora antes de la hora de su dosis. Considere esto como un suave recordatorio para tomar su medicamento dentro de la próxima hora.</p>
        <p>Si pasa una hora y aún no ha tomado su medicamento, las alertas de recordatorio se detendrán. En su lugar, recibirá una llamada telefónica o un mensaje de texto para verificar su estado y por qué omitió su dosis.</p>`,
      },
      {
        title: `¿Cuándo necesito recargar el frasco?`,
        content: `<p>El frasco inteligente Aidia llega completamente cargado, con suficiente energía para hasta 10 meses de uso en la mayoría de los casos. Le recomendamos que cargue una vez al mes para evitar recibir una notificación de batería baja. Cuando sea el momento de recargar, recibirá una notificación de batería baja con instrucciones para usar el cable provisto. Asegúrese de guardar el cable del cargador para uso futuro.</p>`,
      },
      {
        title: `¿Cómo recargo mi frasco?`,
        content: `<p>Levante la solapa de goma negra en la parte inferior de la botella y busque el puerto de carga. Inserte el enchufe del cargador en el frasco y conéctelo a una toma de corriente.</p>
        <p>Una vez enchufado y cargando, el frasco brillará en color púrpura. El brillo cambia a verde cuando está completamente cargado. Su frasco debe estar completamente cargado dentro de las 8 horas.</p>
        <p>[Imagen: diagrama del frasco de pastillas que muestra las instrucciones de recarga]</p>
        <p><div class="photo"><img src=${rechargeBottle} alt="recharge bottle"/></div></p>`,
      },
      {
        title: `¿Puedo viajar con mi frasco?`,
        content: `<p>Absolutamente. Su frasco inteligente Aidia se puede empacar en una maleta facturada o en el equipaje de mano y funciona en todo el mundo con servicio celular.</p>`,
      },
      {
        title: `¿Puedo poner el frasco en agua?`,
        content: `<p>No. El frasco contiene partes electrónicas sensibles que se dañarán con el agua. No sumergir en líquido. Evite que entre líquido o humedad dentro del frasco, la tapa del frasco o alrededor de las aberturas del frasco.</p>
        <p>Además, no exponga su frasco al calor y no use limpiadores abrasivos o químicos.</p>`,
      },
      {
        title: `¿Qué pasa si mi frasco deja de funcionar?`,
        content: `<p>Si esto sucede, llame a un especialista de Aidia o a su farmacia para obtener ayuda. El número gratuito está impreso en la parte inferior de su frasco Aidia.</p>`,
      },
    ],
  },
  {
    category: `Acerca de su Aidia Smart Cap`,
    questions: [
      {
        title: `He recibido mi Aidia Smart Cap. ¿Ahora que?`,
        content: `<p>Una vez que haya abierto la caja, observe las guías plegadas que están ubicadas en la ranura detrás del Smart Cap. Asegúrese de leer todos los materiales, en particular, la guía de bienvenida, que contiene toda la información que necesita para comenzar.</p>
        <p>Para adjuntar su Smart Cap a su frasco de medicamentos, siga los pasos en la guía de bienvenida o <a class="faq-link" href="https://www.youtube.com/watch?v=PlJPTyrG6Xg">mire este video</a>.</p>
        <p>Para activar su Smart Cap, simplemente presione el botón azul. Su dispositivo está preestablecido para su horario de dosis y debe tener suficiente batería para durar entre 2 a 3 meses.</p>`,
      },
      {
        title: `¿Qué hace el botón azul?`,
        content: `<p>Después de la configuración, presione el botón azul en cualquier momento para ver información útil. Si desea ver la última vez que abrió el frasco del medicamento, presione el botón una vez. Si desea confirmar su horario de dosis, presione el botón dos veces. Para verificar el estado de la batería, presione el botón tres veces.</p>`,
      },
      {
        title: `¿Qué sucede cuando me llega un relleno de una receta?`,
        content: `<p>Cuando reciba el relleno de su receta, transfiera el Smart Cap a su nuevo frasco de medicamentos. Se recomienda que retire la tapa vieja del interior de la tapa inteligente e inserte la nueva.</p>
        <p>Si ha recibido dos frascos con su receta, use la tapa Smart Cap en solo uno de los frascos.</p>
        <p>Si su relleno llega en un frasco más grande o más pequeño, deberá reemplazar el adaptador y el anillo para que la tapa inteligente se ajuste al frasco del nuevo tamaño. Debería recibir un "Kit de intercambio" con un nuevo anillo y adaptador, junto con instrucciones de como cambiar la tapa Smart Cap.</p>
        <p>Guarde y consulte la etiqueta de prescripción de la farmacia para conocer las instrucciones de uso de su medicamento y cualquier otro detalle del medicamento que necesite consultar, como la Información de prescripción del medicamento para el paciente.</p>`,
      },
      {
        title: `¿Por qué el Smart Cap brilla antes de la hora de mi dosis?`,
        content: `<p>Ese brillo azul aparece una hora antes de la hora de su dosis. Considere esto como un leve recordatorio para tomar su medicamento dentro de la próxima hora. La pantalla también mostrará una barra de progreso para darle una idea de cuánto tiempo queda hasta la hora de su dosis.</p>
        <p>Si pasa una hora y aún no ha tomado su medicamento, las alertas del recordatorio se detendrán. En su lugar, recibirá una llamada telefónica o un mensaje de texto para saber de usted y verificar por qué omitió su dosis.</p>`,
      },
      {
        title: `¿Cuándo necesito recargar el Smart Cap?`,
        content: `<p>El Aidia Smart Cap llega cargado, con suficiente carga para hasta 2 a 3 meses de uso en la mayoría de los casos. Cuando sea el momento de cargar, el Smart Cap mostrará un mensaje de batería baja. En cualquier momento, puede verificar el nivel de la batería presionando el botón azul tres veces.</p>`,
      },
      {
        title: `¿Cómo cargo el Smart Cap?`,
        content: `<p>La caja de su Aidia Smart Cap contiene un cargador, ubicado en un compartimiento etiquetado. Asegúrese de guardar el cargador en un lugar seguro.</p>
        <p>Cuando sea el momento de cargar, levante la tapa de goma en la parte superior del Smart Cap y busque el puerto de carga. Inserte el cargador proporcionado y conéctelo a un toma de corriente.</p>
        <p>Una vez enchufado y cargando, la tapa se iluminará en color naranja. El brillo cambia a verde cuando esté completamente cargada. Mientras se carga, los recordatorios de dosis seguirán funcionando como de costumbre.</p>`,
      },
      {
        title: `¿Puedo viajar con la Smart Cap?`,
        content: `<p>Absolutamente. Su Aidia Smart Cap se puede empacar en una maleta registrada o de mano y funciona en todo el mundo con servicio celular.</p>`,
      },
      {
        title: `¿Puedo poner el Smart Cap en agua?`,
        content: `<p>No. La tapa contiene partes electrónicas sensibles que se dañarán en el agua. No sumergir en líquido. Evite que entre líquido o humedad dentro del dispositivo o alrededor de sus aperturas. Además, no exponga su Smart Cap al calor y no use limpiadores abrasivos o a base de químicos.</p>`,
      },
      {
        title: `¿Qué pasa si el Smart Cap deja de funcionar?`,
        content: `<p>Si esto sucede, llame a un especialista de Aidia o a su farmacia para obtener ayuda. El número gratuito está impreso en la parte superior de su Aidia Smart Cap. Pueden solucionar problemas o reemplazar una tapa rota.</p>`,
      },
    ],
  },
]
