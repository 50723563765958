import React from 'react'
import CallToActionLink from './CallToActionLink'

export default function CallToAction({ headerText, content, linkText, linkUrl, color, external, children }) {
  return (
    <div className={`call-to-action ${color}`}>
      <div className="border-line"></div>
      <h2 className="medium-heading">{headerText}</h2>
      {content && <p>{content}</p>}
      {!content && children && <p>{children}</p>}
      <CallToActionLink color={color} linkText={linkText} linkUrl={linkUrl} external={external} />
    </div>
  )
}

CallToAction.defaultProps = {
  color: 'green',
}
