import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Hero({ color, text, heading, image, children }) {
  const desktopWidth = 300
  const mobileWidth = 150

  const dataDevicesImageUrl = '../images/data-devices.png'
  const clockDevicesImageUrl = '../images/clock-devices.png'
  const calendarClockImageUrl = '../images/calendar-clock.png'

  const renderStaticHeroImage = (device) => {
    if (device === 'mobile') {
      if (image === 'data-devices') {
        return (
          <StaticImage src={dataDevicesImageUrl} alt="hero" placeholder="none" layout="fixed" width={mobileWidth} />
        )
      } else if (image === 'calendar-clock') {
        return (
          <StaticImage src={calendarClockImageUrl} alt="hero" placeholder="none" layout="fixed" width={mobileWidth} />
        )
      } else if (image === 'clock-devices') {
        return (
          <StaticImage src={clockDevicesImageUrl} alt="hero" placeholder="none" layout="fixed" width={mobileWidth} />
        )
      }
      return null
    }

    if (image === 'data-devices') {
      return <StaticImage src={dataDevicesImageUrl} alt="hero" placeholder="none" layout="fixed" width={desktopWidth} />
    } else if (image === 'calendar-clock') {
      return (
        <StaticImage src={calendarClockImageUrl} alt="hero" placeholder="none" layout="fixed" width={desktopWidth} />
      )
    } else if (image === 'clock-devices') {
      return (
        <StaticImage src={clockDevicesImageUrl} alt="hero" placeholder="none" layout="fixed" width={desktopWidth} />
      )
    }
    return null
  }

  return (
    <div className="hero__container">
      <div className={`hero hero--${color}`}>
        <div className="hero-content">
          <div className={`hero-text hero--${color}`}>
            <h1>{heading}</h1>
            {text && <p className="hero-text-desktop">{text}</p>}
            {!text && children && <div className="hero-text-desktop">{children}</div>}
          </div>
          <div className="hero-image-desktop">{renderStaticHeroImage('desktop')}</div>
          <div className="hero-image-mobile">{renderStaticHeroImage('mobile')}</div>
        </div>
      </div>
      {text && <p className="hero-text-mobile">{text}</p>}
      {!text && children && <div className="hero-text-mobile">{children}</div>}
    </div>
  )
}

Hero.defaultProps = {
  color: 'grayblue',
}
