import React, { useState, useEffect } from 'react'

export default function Accordion(props) {
  const { panels } = props

  const [activeIndices, setActiveIndices] = useState([])

  useEffect(() => {
    let newActiveIndices = []
    props.activeIndices.forEach((index) => {
      newActiveIndices[index] = true
    })
    setActiveIndices(newActiveIndices)
  }, [props.activeIndices])

  const className = () => {
    let className = 'accordion'
    if (props.className) className += ` ${props.className}`
    return className
  }

  const setPanelActive = (index, newActiveState) => {
    const newActiveIndices = [...activeIndices]
    newActiveIndices[index] = newActiveState
    setActiveIndices(newActiveIndices)
  }

  const togglePanel = (index) => {
    setPanelActive(index, !activeIndices[index])
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      togglePanel(index)
    } else if (e.key === 'ArrowUp') {
      setPanelActive(index, false)
      e.preventDefault()
    } else if (e.key === 'ArrowDown') {
      setPanelActive(index, true)
      e.preventDefault()
    }
  }

  return (
    <div className={className()}>
      {panels.map((panel, i) => {
        return (
          <div className={`accordion-panel ${activeIndices[i] ? 'active' : ''}`} key={panel.key || `panel_${i}`}>
            <div
              className="accordion-panel-header"
              role="button"
              tabIndex="0"
              onClick={() => togglePanel(i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
            >
              <i aria-hidden="true" className={`dropdown-icon ${activeIndices[i] ? 'open' : ''}`}></i>
              <h4 className="accordion-panel-title">{panel.title}</h4>
            </div>
            <div
              className={`accordion-panel-content ${activeIndices[i] ? 'active' : ''}`}
              dangerouslySetInnerHTML={{ __html: panel.content }}
            ></div>
          </div>
        )
      })}
    </div>
  )
}

Accordion.defaultProps = {
  panels: [],
  activeIndices: [],
}
