import React from 'react'
import { Link } from 'gatsby'
import { FaArrowRight } from 'react-icons/fa'

export default function CallToActionLink({ linkUrl, linkText, color, external }) {
  const iconSize = 28
  const className = () => {
    let className = 'call-to-action-link'
    if (color === 'blue') className += ' call-to-action-link__primary'
    if (color === 'green') className += ' call-to-action-link__secondary'
    return className
  }

  if (external) {
    return (
      <a className={className()} href={linkUrl} target="_blank" rel="noopener noreferrer">
        {linkText} <FaArrowRight className="arrow-right" size={iconSize} />
      </a>
    )
  } else {
    return (
      <Link className={className()} to={linkUrl}>
        {linkText} <FaArrowRight className="arrow-right" size={iconSize} />
      </Link>
    )
  }
}

CallToActionLink.defaultProps = {
  color: 'green',
  external: false,
}
