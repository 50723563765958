import rechargeBottle from '../../images/recharge-bottle.png'

export default [
  {
    category: `About the Aidia System™`,
    questions: [
      {
        title: `Why is adherence important?`,
        content: `<p>Medication adherence simply means taking your medicine as prescribed by your doctor. This includes taking the correct dose at the right time and refilling prescriptions in a timely manner.</p>
        <p>The problem is that it can be difficult to maintain. Up to 50% of people miss doses regularly for one reason or another. Poor adherence can have a negative impact on your health. It can prolong your condition or complicate it. The key to adherence is <strong>consistency</strong>—it often requires a good support system to help keep you on track.</p>
        <p>You can learn more about adherence <a class="faq-link" href="https://www.fda.gov/drugs/special-features/why-you-need-take-your-medications-prescribed-or-instructed" target="_blank" rel="noopener noreferrer">here</a>.</p>`,
      },
      {
        title: `What exactly is the Aidia System?`,
        content: `<p>Aidia is a smart adherence system designed to help people like you stay on track with medication dosing schedules. This system uses smart bottles and devices to alert you to your scheduled dose time, tracks if you have taken your dose, and reminds you if you are late or have missed a dose.</p>
        <p>Aidia also provides friendly support that can help change settings and even connects you to your pharmacy team or care support for refill assistance or additional care.</p>`,
      },
      {
        title: `How much will it cost me to use Aidia?`,
        content: `<p>Nothing. Your Aidia System is likely sponsored by the manufacturer of your medication or healthcare organization, which allows us to deliver this service at no cost to you.</p>`,
      },
      {
        title: `How do I use Aidia?`,
        content: `<p>An hour before your dose time approaches, your Aidia device will give off a blue glow as a gentle reminder. Take your medication at any point during this time and your reminders will stop.  At your scheduled dose time, Aidia will briefly chime and a reminder text or phone call will be sent. The alerts will end once you open the bottle and take your medicine.</p>
        <p>Want to personalize or change any of these features? Call your Aidia Specialist or pharmacy to adjust your reminder notification settings.</p>`,
      },
      {
        title: `What happens if I do not take my medication on time?`,
        content: `<p>If you or your loved one misses a dose, reminder alerts kick in. Reminder alerts (chimes, texts, and/or phone calls) are designed to make sure that a dose is taken as soon as possible.</p>`,
      },
      {
        title: `How can I change my dose times or settings?`,
        content: `<p>Just call your pharmacy or contact an Aidia Specialist. We can update your dose times, pause reminders, and personalize your device or phone settings. Simply call the number printed on the bottom of your Smart Bottle or the top of your Smart Cap for convenience.</p>`,
      },
    ],
  },
  {
    category: `About Aidia reminder alerts`,
    questions: [
      {
        title: `What are reminder alerts?`,
        content: `<p>Reminder alerts are just that—reminders—sent by Aidia to help you stay on time with your medication dosing. At your scheduled dose time, your Aidia device will briefly chime. You will also receive a text or phone call. The alerts will end once you open the bottle and take your medicine.</p>`,
      },
      {
        title: `Why did my reminder alert not go off?`,
        content: `<p>Reminder alerts are connected to when and how you engage with your Aidia device. So, let’s say you open the bottle close to your scheduled dose time, your reminders will be deactivated until your next dose time.</p>`,
      },
      {
        title: `How can my caregiver also receive text or phone reminders?`,
        content: `<p>An Aidia Specialist can help you with that. Give us a call to add a caregiver phone number within minutes.</p>`,
      },
    ],
  },
  {
    category: `About your Aidia Smart Bottle`,
    questions: [
      {
        title: `I have received my Aidia Smart Bottle. Now what?`,
        content: `<p>Once you’ve taken the Aidia Smart Bottle out of the packaging, simply remove the orange pull-tab to activate—<span class='italic'>if your pharmacy hasn’t already</span>—and you’re good to go. Your bottle requires zero setup and is fully charged for up to 10 months in most cases.</p>
        <p>To see how easy it is to start using Aidia, <a href="https://youtu.be/v-1nzNUIN6M" target="_blank">watch this video</a>.</p>
        <p>Refer to the User Manual that you received with your Aidia Smart Bottle for more information.</p>`,
      },
      {
        title: `The bottle arrived empty. Where is my medication?`,
        content: `<p>In certain instances, your Aidia Smart Bottle may not come with your medication inside. If that is the case and your medication comes separately, simply transfer the contents of your prescription bottle into the Aidia Smart Bottle.</p>
        <p>Keep and refer to the pharmacy prescription label for your medication directions of use and any other medication details that you may need to refer to, like the medicine’s Patient Prescribing Information.</p>`,
      },
      {
        title: `What if all of my pills don’t fit into the Aidia Smart Bottle?`,
        content: `<p>That may occasionally happen as pill sizes vary. But it’s not a problem. Simply leave the pills that do not fit in the Aidia Smart Bottle in the original prescription container. Transfer the remainder into your smart bottle when you are running low.</p>
        <p>The best time to transfer your pills is at dose time when you have opened your Aidia Smart Bottle.</p>`,
      },
      {
        title: `What happens when I get a prescription refill?`,
        content: `<p>When you receive your refill, transfer the medication into your Aidia Smart Bottle. Wait until your next scheduled dose time to make the transfer, since opening the bottle will be counted as taking a dose—and that may throw off your schedule.</p>
        <p>Keep and refer to the pharmacy prescription label for your medication directions of use and any other medication details that you may need to refer to, like the medicine’s Patient Prescribing Information.</p>`,
      },
      {
        title: `Why is the bottle glowing before my dose time?`,
        content: `<p>That blue glow appears one hour before it is time for your dose. Consider this a gentle reminder to take your medicine within the next hour.</p>
        <p>If an hour goes by and you still haven’t taken your medication, the reminder alerts will stop. Instead, you will receive a phone call or text message checking in on you and why you missed your dose.</p>`,
      },
      {
        title: `When do I need to recharge the bottle?`,
        content: `<p>The Aidia Smart Bottle arrives fully charged, with enough power for up to 10 months of use in most cases. We recommend you charge once per month to avoid getting a low battery notification. When it’s time to recharge, you’ll receive a low battery notification with instructions to use the cord provided. Be sure to keep the charger cord for future use.</p>`,
      },
      {
        title: `How do I recharge the bottle?`,
        content: `<p>Lift the black rubber flap on the bottom of the bottle and look for the charging port. Insert the charger plug into the bottle and plug into a power outlet.</p>
        <p>Once plugged in and charging, the bottle will glow purple. The glow changes to green when it is fully charged. Your bottle should be fully charged within 8 hours.</p>
        <p><div class="photo"><img src=${rechargeBottle} alt="recharge bottle"/></div></p>`,
      },
      {
        title: `Can I travel with the bottle?`,
        content: `<p>Absolutely. Your Aidia Smart Bottle can be packed in a checked bag or carry-on and works around the world with cellular service.</p>`,
      },
      {
        title: `Can I put the bottle in water?`,
        content: `<p>No. The bottle contains sensitive electronic parts that will be damaged in water. Do not submerge in liquid. Avoid getting liquid or moisture inside the bottle, bottle cap, or around the bottle’s openings.</p>
        <p>In addition, do not expose your bottle to heat, and do not use abrasive and/or chemical-based cleaners.</p>`,
      },
      {
        title: `What if the bottle stops working?`,
        content: `<p>If this happens, call an Aidia Specialist or your pharmacy for assistance. The toll-free number is printed on the bottom of your Aidia Smart Bottle.</p>`,
      },
    ],
  },
  {
    category: `About your Aidia Smart Cap`,
    questions: [
      {
        title: `I have received my Aidia Smart Cap. Now what?`,
        content: `<p>Once you’ve opened the box, note the folded guides located in the slot behind the Smart Cap. Make sure to read all the materials – in particular, the Welcome Guide, which contains all the information you need to get started.</p>
        <p>To attach your Smart Cap to your medication bottle, follow the steps in the Welcome guide or <a class="faq-link" href="https://www.youtube.com/watch?v=PlJPTyrG6Xg">watch this video</a>.</p>
        <p>To activate your Smart Cap, simply press the blue button. Your device is pre-set to your dose schedule and should have enough battery to last 2-3 months.</p>`,
      },
      {
        title: `What does the blue button do?`,
        content: `<p>After setup, press the blue button at any time to view helpful information. If you want to see the last time you opened the medication, press the button once. If you want to confirm your dose schedule, press the button twice. To check battery status, press the button three times.</p>`,
      },
      {
        title: `What happens when I get a prescription refill?`,
        content: `<p>When you receive your prescription refill, transfer the Smart Cap to your new medication bottle. It is recommended that you remove the old medication cap from inside the Smart Cap and insert the new one.</p>
        <p>If you have received two bottles with your prescription, please use the Smart Cap on just one of the bottles.</p>
        <p>If your refill medication comes in a larger or smaller bottle, you will need to replace the adapter and ring in order for the Smart Cap to fit the new sized bottle. You should receive a “Swap Kit” with a new ring and adapter, along with a set of instructions.</p>
        <p>Keep and refer to the pharmacy prescription label for your medication directions of use and any other medication details that you may need to refer to, like the medicine’s Patient Prescribing Information.</p>`,
      },
      {
        title: `Why is the Smart Cap glowing before my dose time?`,
        content: `<p>That blue glow appears one hour before it is time for your dose. Consider this a gentle reminder to take your medicine within the next hour. The screen will also display a progress bar to give you a sense of how much time is left until your dose time.</p>
        <p>If an hour goes by and you still haven’t taken your medication, the reminder alerts will stop. Instead, you will receive a phone call or text message checking in on you and why you missed your dose.</p>`,
      },
      {
        title: `When do I need to recharge the Smart Cap?`,
        content: `<p>The Aidia Smart Cap arrives charged, with enough power for up to 2-3 months of use in most cases. When it’s time to recharge, the Smart Cap will display a low battery message. At any time, you can check the battery level by pressing the blue button three times.</p>`,
      },
      {
        title: `How do I recharge the Smart Cap?`,
        content: `<p>Your Aidia Smart Cap box contains a charger, located in a labeled compartment. Be sure to store the charger somewhere safe.</p>
        <p>When it’s time to recharge, lift the rubber flap on top of the Smart Cap and look for the charging port. Insert the charger provided and plug it into a power outlet.</p>
        <p>Once plugged in and charging, the cap will glow amber. The glow changes to green when it is fully charged. While it’s charging, your dose reminders will continue working as usual.</p>`,
      },
      {
        title: `Can I travel with the Smart Cap?`,
        content: `<p>Absolutely. Your Aidia Smart Cap can be packed in a checked bag or carry-on and works around the world with cellular service.</p>`,
      },
      {
        title: `Can I put the Smart Cap in water?`,
        content: `<p>No. The cap contains sensitive electronic parts that will be damaged in water. Do not submerge in liquid. Avoid getting liquid or moisture inside the device, or around its openings. In addition, do not expose your Smart Cap to heat, and do not use abrasive and/or chemical-based cleaners.</p>`,
      },
      {
        title: `What if the Smart Cap stops working?`,
        content: `<p>If this happens, call an Aidia Specialist or your pharmacy for assistance. The toll-free number is printed on top of your Aidia Smart Cap. They can troubleshoot or replace a broken cap.</p>`,
      },
    ],
  },
]
